/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-05 21:25:33
 * @LastEditTime: 2021-02-21 14:37:53
 * @LastEditors: dingxuejin
 */
import React, { lazy, PureComponent as Component } from "react";
import css from './index.module.scss';
import navData from '@/router/about'
import $ from 'jquery'
import ItemGroup from "../news/components/ItemGroup";
import Slider from "../../components/common/Slider";
import about_iocn_vision from "@/assets/images/about/about_iocn_vision.svg"
import about_iocn_target from "@/assets/images/about/about_iocn_target.svg"
import about_iocn_mission from "@/assets/images/about/about_iocn_mission.svg"
import about_area from '@/assets/images/about/about_area.jpg';
import {concatClass} from "../../utils/methods";
import cover_area_bg from '../../assets/images/about/cover_area_bg.png'
const Item = lazy(() => import("@/pages/product/components/item/index"));
const Dynamic = lazy(() => import("@/pages/home/components/dynamic"));
const TapBar = lazy(() => import("./components/tapBar"));
const Honor = lazy(() => import("./honor"));

class About extends Component {
    constructor(props) {
        super(props);
        this.slider = React.createRef();
        this.state = {
            history: [
                {
                    time: '2021年',
                    des: ['东经易网平台向全行业免费开放']
                },
                {
                    time: '2020年',
                    des: ['智能排产系统发布', '销售精细化+业务员看板V2.0发布', '销售精细化+业务员看板V2.1发布']
                },
                {
                    time: '2019年',
                    des: ['大数据平台+数据驾驶舱发布']
                },
                {
                    time: '2018年',
                    des: ['东经智能管理平台上线，构建了包装行业SAAS化，集成化供应链服务平台', '智能供应链，正式发布上线']
                },
                {
                    time: '2017年',
                    des: ['以平台模式走出温州，完成首个城市的复制BI可视化管理系统上线发布']
                },
                {
                    time: '2016年',
                    des: ['东经易网上线，创新“包装+互联网”商业模式']
                },
                {
                    time: '2015年',
                    des: ['“公司战略转型路线图与业务模式再造”东经将打造以智能包装服务为特色的工业互联网平台', '区域供应链物流产品“一路好运”APP上线']
                },
                {
                    time: '2003年',
                    des: ['进行战略转型，走出从“包装制造的东经”向“包装服务的东经”转型第一步']
                },
                {
                    time: '1994年',
                    des: ['东经科技前身“新瓯工艺纸品厂”成立，开始纸包装行业创业']
                },
            ],
            activeHistory: 0
        };
    }

    componentDidMount() {
        setTimeout(()=>{
            this.historyDrag();
        });
    }

    // 发展历程时间条与内容联动效果
    historyDrag = () => {
        let self = this;
        let historyContent = $(`#his .${css['bot']}`)
        let historyMainBox = $(`#his .${css['itm']}`)
        let node = $(`#his .${css["top"]} li`)
        var minX = {number:999999,object:null};
        historyMainBox.each(function (){
            let number = Math.abs( $(this).offset().left - historyContent.offset().left);
            if(minX.number > number){
                minX.number = number;
                minX.object = this;
            }
        });
        node.each(function (index){
            if($(this).data('year') == $(minX.object).attr('year')){
                self.setState({
                    activeHistory: index
                })
            }
        });
    }

    onClickHistoryPoint = (index) => {
        this.slider.current.goByIndex(index, 100);
    }

    render() {
        let {history, activeHistory} = this.state;
        return (
            <div className={css["djabout"]}>
                <TapBar {...this.props} navData={navData.children}></TapBar>
                <Item title="企业简介" id="int">
                    <div className={css["con"]}>
                        <p>浙江东经科技股份有限公司创办于1994年，有近30年的纸包装行业运营及管理经验；于2013年开创并实际应用“包装+互联网”模式，将互联网技术引入传统包装行业；助力包装行业的供给侧改革及中小民营包装企业的健康发展。</p>
                        <p>东经科技凭借在包装领域积累的丰富经验，基于纸板厂、纸箱厂用户场景，围绕生产经营全流程各环节痛点，赋能产业服务，以最新的产业互联网技术、工业大数据技术和智能制造技术，为平台用户提供专业、高效、智能的包装供应链数字化解决方案及服务，助力中小企业在大数据浪潮中降低综合成本，提升品牌价值，实现业绩高效增长。 现业务范围已覆盖包括浙江、山东、四川、湖北、福建、天津等省市区域，平台注册企业级用户超33000家，直接服务用户近万家。</p>
                    </div>
                </Item>
                <Item title="东经文化">
                    <div className={css["con"]} id="cul">
                        <ItemGroup layout={[[41.75, 58.25]]}>
                            <div className={css["top"]}>
                                <div className={css["itm"]}>
                                    <div className={css["ico"]} style={{backgroundImage: `url(${about_iocn_vision})`}}></div>
                                    <div className={css["info"]}>
                                        <div className={css["titl"]}>愿景</div>
                                        <div className={css["des"]}>成为包装规则的制定者</div>
                                    </div>
                                </div>
                                <div className={css["itm"]}>
                                    <div className={css["ico"]} style={{backgroundImage: `url(${about_iocn_target})`}}></div>
                                    <div className={css["info"]}>
                                        <div className={css["titl"]}>目标</div>
                                        <div className={css["des"]}>打造中小企业降本提效、价值倍增的包装供应链服务平台</div>
                                    </div>
                                </div>
                                <div className={css["itm"]}>
                                    <div className={css["ico"]} style={{backgroundImage: `url(${about_iocn_mission})`}}></div>
                                    <div className={css["info"]}>
                                        <div className={css["titl"]}>使命</div>
                                        <div className={css["des"]}>为价值而奋斗</div>
                                    </div>
                                </div>
                            </div>
                            <div className={css["bot"]}>
                                <div className={css['titl']}>价值观</div>
                                {/*使用两个遮罩实现三角形的效果*/}
                                <div className={css['wrap']}>
                                    <div className={css['row']}>
                                        <div className={css['itm']}>
                                            <div>用户至上</div>
                                            <div>
                                              <p>尽全力为用户</p>
                                              <p>降本、提效、增值</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={css['row']}>
                                        <div className={css['itm']}>
                                            <div>简单高效</div>
                                            <div>
                                                <p>简单做人</p>
                                                <p>高效做事</p>
                                            </div>
                                        </div>
                                        <div className={css['itm']}>
                                            <div>日省日高</div>
                                            <div>
                                                <p>每天自我反思</p>
                                                <p>每天进步一点点</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={css['row']}>
                                        <div className={css['itm']}>
                                            <div>齐心合力</div>
                                            <div>
                                                <p>齐心达成共识</p>
                                                <p>合力创造共赢</p>
                                            </div>
                                        </div>
                                        <div className={css['itm']}>
                                            <div>积极主动</div>
                                            <div>积极挑战，主动担当</div>
                                        </div>
                                        <div className={css['itm']}>
                                            <div>诚实守信</div>
                                            <div>
                                                <p>诚实是品德</p>
                                                <p>守信是能力</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={css['mask-left']}></div>
                                    <div className={css['mask-right']}></div>
                                </div>
                                {/*<div className={css["icon"]}>*/}
                                {/*    <div className={css["img"]}></div>*/}
                                {/*    /!*<div className={css["des"]}>价值观</div>*!/*/}
                                {/*</div>*/}
                                {/*<div className={css["lst"]}>*/}
                                {/*    <div className={css["title"]}>价值观</div>*/}
                                {/*    <div className={css["itm"]}>*/}
                                {/*        <p><i className={css["titl"]}>用户至上：</i>尽全力为用户</p>*/}
                                {/*        <p><i className={css["titl"]}>齐心合力：</i>齐心达成共识，合力创造共赢</p>*/}
                                {/*    </div>*/}
                                {/*    <div className={css["itm"]}>*/}
                                {/*        <p><i className={css["titl"]}>简单高效：</i>简单做人，高效做事</p>*/}
                                {/*        <p><i className={css["titl"]}>日省日高：</i>每天自我反思，每天进步一点点</p>*/}
                                {/*    </div>*/}
                                {/*    <div className={css["itm"]}>*/}
                                {/*        <p><i className={css["titl"]}>诚实守信：</i>诚实是品德，守信是能力</p>*/}
                                {/*        <p><i className={css["titl"]}>积极主动：</i>积极挑战，主动担当</p>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </ItemGroup>
                    </div>
                </Item>
                <Item title="发展历程" className={css['history']} color="#ffffff">
                    <div className={css["con"]} id="his">
                        <div className={css["top"]}>
                            <ul>
                                {
                                    history.map((item, index)=>(
                                      <li className={activeHistory === index ? 'active': ''} data-year={item.time}>
                                          <div className={css["tet"]}>{index && item.time || '现在'}</div>
                                          <div className={css["cir"]} onClick={this.onClickHistoryPoint.bind(this, index)}></div>
                                      </li>
                                    ))
                                }
                            </ul>
                        </div>
                        <div className={css["bot"]}>
                            <Slider ref={this.slider} onMove={this.historyDrag} noBtn={true} endPos="start">
                                {
                                    history.map(item=>(
                                      <div className={css["itm"]} year={item.time}>
                                          <div className={css["titl"]}>{item.time}</div>
                                          <div className={css["des"]}>{
                                              item.des.map(str=>(<p>{str}</p>))
                                          }</div>
                                      </div>
                                    ))
                                }
                            </Slider>
                        </div>
                    </div>
                </Item>
                <Honor></Honor>
                <Item title="覆盖区域">
                    <div className={css["con"]} id="area">
                        <img className={css["area"]} src={about_area} alt=""/>
                    </div>
                </Item>
                <Dynamic></Dynamic>
            </div >
        );
    }
}

export default About;
