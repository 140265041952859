import React, {PureComponent as Component} from 'react';
import Slider from "../../../../components/common/Slider";
import css from './index.module.scss'
import Tab from "../../../../components/common/Tab";
import $ from 'jquery';
import {throttle, getTransition} from "../../../../utils/methods";

export default class AppBoar extends Component{
  transition = getTransition();
  constructor(props) {
    super(props);

    this.state = {
      active: 0,
      tabs: [
        {
          label: '企业简介',
          value: 0,
        },
        {
          label: '企业文化',
          value: 1
        },
        {
          label: '覆盖区域',
          value: 2
        },
      ],
      paddingRight: '0'
    }
  }

  componentDidMount() {
    setTimeout(this.initSwiper);
    window.addEventListener('resize', this.initSwiper);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.initSwiper);
  }

  initSwiper = throttle(20, () => {
    let items = $(`.${css['appBoar_item']}`);
    let offset = parseFloat($(document.documentElement).css('font-size')) * 60 / 100;
    this.setState({paddingRight: $(`.${css['appBoar_content']}`).width() - $(items[items.length - 1]).width() - offset})
    this.mySwiper = new window.Swiper(`.swiper-container`,{
      slidesPerView: 'auto',
      offsetPxBefore: offset,
      onSlideChangeStart: (swiper) => {
        this.setState({active: swiper.activeIndex})
      }
    })
  })

  tabChange = ({value}) => {
    // this.setState({active: value});
    this.scrollTo(value);
  }

  scrollTo = (value) => {
    let index = this.state.tabs.findIndex(obj => obj.value === value);

    this.mySwiper.swipeTo(index);

    // let context = $(`.${css['appBoar_content']}`);
    // let item = $(`.${css['appBoar_item']}`)[index];
    // let point = context.width() / 2;
    // let {left, right} = item.getBoundingClientRect();
    // let itemPoint = (right + left) / 2;
    // let currentLeft = context.scrollLeft();
    // this.transition(currentLeft, currentLeft + itemPoint - point, left =>{
    //   context.scrollLeft(left);
    // }, 200);
    // context.scrollLeft(context.scrollLeft() + itemPoint - point);
  }

  onTouchMove = throttle(20, (e) => {
    let context = $(`.${css['appBoar_content']}`);
    let items = $(`.${css['appBoar_item']}`);
    let point = context.width() / 2;
    let index = [].findIndex.call(items, (el, index) => {
      let {left, right} = el.getBoundingClientRect();
      return left > point;
    });
    this.setState({active: index === -1 ? items.length : index});
  })

  render() {
    let {active, tabs, paddingRight} = this.state;
    return (
      <div className={css['appBoar']}>
        <div className={css['appBoar_tab']}>
          <Tab value={active} options={tabs} onChange={this.tabChange}></Tab>
        </div>
        <div className={css['appBoar_content']} onScroll={this.onTouchMove}>
          <div className="swiper-container">
            <div className="swiper-wrapper">
              <div className={`swiper-slide ${css['appBoar_item']}`}>
                浙江东经科技股份有限公司创办于1994年，有近30年的纸包装行业运营及管理经验；于2013年开创并实际应用“包装+互联网”模式，将互联网技术引入传统包装行业；助力包装行业的供给侧改革及中小民营包装企业的健康发展。
              </div>
              <div className={`swiper-slide ${css['appBoar_item']}`}>
                愿景：成为包装规则的制定者<br/>
                目标：打造中小企业降本提效、价值倍增的包装供应链服务平台<br/>
                使命：为价值而奋斗<br/>
                价值观：用户至上、简单高效、齐心合力、日省日高、积极主动、诚实守信
              </div>
              <div className={`swiper-slide ${css['appBoar_item']}`} style={{paddingRight: paddingRight + 'px'}}>
                平台已覆盖浙江省9个主要城市，并拓展至湖北、四川，为近5000家纸箱厂提供高性价比、准时交付的纸板，帮助近20家纸板厂增加订单、降本提效，提高经营效益。
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
