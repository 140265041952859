/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-07 14:56:08
 * @LastEditTime: 2021-02-07 18:30:23
 * @LastEditors: dingxuejin
 */

/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-05 21:25:33
 * @LastEditTime: 2021-02-07 14:56:33
 * @LastEditors: dingxuejin
 */
import {PureComponent as Component} from "react";
import './index.scss';
import qrCode1 from '@/assets/images/footer/home_yiwang__navigation.png';
import qrCode2 from '@/assets/images/footer/home_bottom_app.png';
import locationIcon from '@/assets/images/footer/home_bottom_iocn_navigation.svg';
import securityIcon from '@/assets/images/footer/home_bottom_iocn_gongan.svg';
import officialService from '@/api/service/official';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            year: ""
        };
        this.getYear();
    }

    getYear = () => {
        officialService.getSystemDate().then(res => {
            this.setState({
                year: res.systemDate
            });
        }).finally(() => {});
    };

    render() {
        return (
            <div className="dj-footer">
                <div className="dj-footer-top">
                    <div className="dj-footer-top-item">
                        <div className="dj-footer-top-item-title">
                            浙江东经科技股份有限公司
                        </div>
                        <p>总部：温州市瓯海经济开发区东经一路1号</p>
                        {/*<p>工业园：温州104国道丽岱段东经路一号</p>*/}
                        <p>邮编：325014</p>
                        <a className="loca"
                           target="_blank"
                           href="https://map.baidu.com/poi/%E4%B8%9C%E7%BB%8F%E5%8C%85%E8%A3%85/@13433203.828105312,3224019.2503220835,14.85z?uid=a1f42a7bcda00729e6e96558&primaryUid=16460277307192112855&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&querytype=detailConInfo&da_src=shareurl">
                            <img src={locationIcon} alt=""/>
                            <span>一键导航</span>
                        </a>
                    </div>
                    <div className="dj-footer-top-item">
                        <div className="dj-footer-top-item-title">
                            联系我们
                        </div>
                        <p>客户服务热线：400-133-6161</p>
                        <p>联系电话：0577-85399999</p>
                        <p>E-mail：djcps@djcps.com</p>
                    </div>
                    <div className="dj-footer-top-item">
                        <div className="dj-footer-top-item-title">
                            扫一扫
                        </div>
                        <div className="QRCode">
                            <div>
                                <img src={qrCode1} alt=""/>
                                <div>东经易网公众号</div>
                            </div>
                            <div>
                                <img src={qrCode2} alt=""/>
                                <div>东经易网APP</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dj-footer-bot">
                    <span>dongjing.com 版权所有 © 2011-{this.state.year} 浙ICP备15023330号-3</span>
                    <span><img src={securityIcon} alt=""/>浙公网安备 33030402000738号</span>
                </div>
            </div>
        );
    }
}

export default Footer;
