
/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-05 21:25:33
 * @LastEditTime: 2021-02-17 20:07:08
 * @LastEditors: dingxuejin
 */
import { PureComponent as Component } from "react";
import SlideUp from '../../../../components/animation/SlideUp';
import TakeTurns from '../../../../components/animation/common/TakeTurns';
import icon_arrow from './images/icon_arrow.png';
import './index.scss'

class Boar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clickState: [false, false, false]
        };
    }

    onShowClick(index) {
        let clickState = [...this.state.clickState];
        clickState[index] = !clickState[index]
        this.setState({clickState})
    }

    render() {
        let {clickState} = this.state;
        return (
            <div className="dj-home-boar clearfix">
                <div>
                    <TakeTurns>
                        <SlideUp>
                            <div className={`dj-home-boar-ite ${clickState[0] ? 'is-show' : ''}`} onClick={this.onShowClick.bind(this, 0)}>
                                <div className="dj-home-boar-ite-tit">01</div>
                                <div className="dj-home-boar-ite-dec">企业简介
                                    <span className="app_btn dj-home-boar-ite-btn">点击了解<img src={icon_arrow} alt=""/></span>
                                </div>
                                <div className="dj-home-boar-ite-dec-hover">
                                    浙江东经科技股份有限公司创办于1994年，有近30年的纸包装行业运营及管理经验；于2013年开创并实际应用“包装+互联网”模式，将互联网技术引入传统包装行业；助力包装行业的供给侧改革及中小民营包装企业的健康发展。
                                </div>
                            </div>
                        </SlideUp>
                        <SlideUp>
                            <div className={`dj-home-boar-ite ${clickState[1] ? 'is-show' : ''}`} onClick={this.onShowClick.bind(this, 1)}>
                                <div className="dj-home-boar-ite-tit">02</div>
                                <div className="dj-home-boar-ite-dec">企业文化
                                    <span className="app_btn dj-home-boar-ite-btn">点击了解<img src={icon_arrow} alt=""/></span>
                                </div>
                                <div className="dj-home-boar-ite-dec-hover">
                                    <p>愿景：成为包装规则的制定者</p>
                                    <p>目标：打造中小企业降本提效、价值倍增的包装供应链服务平台</p>
                                    <p>使命：为价值而奋斗</p>
                                    <p>价值观：用户至上、简单高效、齐心合力、日省日高、积极主动、诚实守信</p>
                                </div>
                            </div>
                        </SlideUp>
                        <SlideUp>
                            <div className={`dj-home-boar-ite ${clickState[2] ? 'is-show' : ''}`} onClick={this.onShowClick.bind(this, 2)}>
                                <div className="dj-home-boar-ite-tit">03</div>
                                <div className="dj-home-boar-ite-dec">覆盖区域
                                    <span className="app_btn dj-home-boar-ite-btn">点击了解<img src={icon_arrow} alt=""/></span>
                                </div>
                                <div className="dj-home-boar-ite-dec-hover">
                                    平台已覆盖浙江省9个主要城市，并拓展至湖北、四川，为近5000家纸箱厂提供高性价比、准时交付的纸板，帮助近20家纸板厂增加订单、降本提效，提高经营效益。
                                </div>
                            </div>
                        </SlideUp>
                    </TakeTurns>

                </div>
            </div >
        );
    }
}

export default Boar;
